
import { defineComponent } from 'vue';
import SponsorAdd from '@/components/SponsorAdd.vue';
import SponsorsContent from '@/components/SponsorsContent.vue';
import { SponsorInterface } from '@/services/sponsors';
import { store } from '@/store/store';

export default defineComponent({
    name: 'Sponsors',
    components: { SponsorsContent, SponsorAdd },
    computed: {
        canAddSponsor(): boolean {
            return store.getters.isAdmin;
        },
        sortedSponsors(): SponsorInterface[] {
            return store.getters.sortedSponsors;
        }
    }
});
