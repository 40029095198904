
import { defineComponent } from 'vue';
import { defaultSponsorDef } from '@/utils';
import { MUTATION } from '@/store/mutations';
import { store } from '@/store/store';

export default defineComponent({
    name: 'SponsorAdd',
    components: { },
    methods: {
        addSponsor(){
            store.commit(MUTATION.SHOW_ADD_SPONSOR_DIALOG, {
                show: true,
                sponsor: defaultSponsorDef()
            });
        },
    }
});
