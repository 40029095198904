
import SponsorCard from '@/components/SponsorCard.vue';
import { store } from '@/store/store';
import { defineComponent } from 'vue';
import { TWindowSize } from '@/store/state';
import { STATE} from '@/constants';
import { SponsorInterface, SPONSOR_TYPES } from '@/services/sponsors';

export default defineComponent({
    name: 'SponsorsContent',
    components: { SponsorCard },
    data() {
        return {
            SPONSOR_TYPE: SPONSOR_TYPES
        }
    },
    props: {
        showTitle: {
            type: Boolean,
            required: false,
            default: true
        },
        showImg: {
            type: Boolean,
            required: false,
            default: true
        },
        showSponsorDescr: {
            type: Boolean,
            required: false,
            default: true
        },
        sponsors: {
            type: Array as () => SponsorInterface[],
            required: true
        }
    },
    computed: {
        windowSize(): TWindowSize {
            return store.state.windowSize
        },
        viewportHeight(): {height: string} {
            return {
                'height': `calc(${this.windowSize.height}px - 4em)`
            };
        },
        isReady(): boolean {
            return store.state.sponsorsRequestStatus.status === STATE.DONE;
        },
        isLoading(): boolean {
            return store.state.sponsorsRequestStatus.status === STATE.LOADING;
        },
    }
});
