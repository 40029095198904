
import ConfirmAction from '@/components/ConfirmAction.vue';
import { store } from '@/store/store';
import { defineComponent } from 'vue';
import { SponsorInterface, SPONSOR_TYPES } from '@/services/sponsors';
import { ACTION } from '@/store/actions';
import { MUTATION } from '@/store/mutations';
import CSS from 'csstype';
import { STATE } from '@/constants';
import { defaultSponsorDef } from '@/utils';

export default defineComponent({
    name: 'SponsorsCard',
    components: { ConfirmAction },
    data() {
        return {
            SPONSOR_TYPE: SPONSOR_TYPES
        }
    },
    props: {
        sponsor: {
            type: Object as () => SponsorInterface,
            required: true
        },
        imgOnly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showDescr(): boolean {
            return !this.imgOnly;
        },
        showTitle(): boolean {
            return !this.imgOnly;
        },
        canEdit(): boolean {
            return store.getters.isAdmin;
        },
        notReady(): boolean {
            return (
                store.state.removeSponsorRequestStatus[this.sponsor._id]?.status === STATE.LOADING
                || store.state.editSponsorRequestStatus[this.sponsor._id]?.status === STATE.LOADING
            );
        },
        sponsorCardStyle(): CSS.Properties {
            const type = this.sponsor.sponsorType;
            let color = this.sponsor.color ?? '#254d6d';
            switch(type) {
                case SPONSOR_TYPES.NONE: break;
                case SPONSOR_TYPES.BRONZE: color = '#704A07'; break;
                case SPONSOR_TYPES.PLATINUM: color = '#D3D6D8'; break;
                case SPONSOR_TYPES.GOLD: color = '#D3AF37'; break;
                case SPONSOR_TYPES.SILVER: color = '#8C8D91'; break;
            }
            return {
                'border-color': color
            } as CSS.Properties;
        },
        logo(): string {
            const sponsorLogo = this.sponsor.logo ?? '';
            return sponsorLogo === ''? defaultSponsorDef().logo: sponsorLogo;
        },
        hasPhoneNumber(): boolean {
            return this.sponsor.telephone !== ''
        }
    },
    methods: {
        hrefClick(ev: MouseEvent, url?: string) {
            if (url === undefined || url === '') {
                ev.preventDefault();
                ev.stopPropagation();
                return false;
            }
        },
        removeSponsor() {
            store.dispatch(ACTION.REMOVE_SPONSOR, this.sponsor._id);
        },
        editSponsor() {
            store.commit(MUTATION.SHOW_ADD_SPONSOR_DIALOG, {
                show: true, sponsor: this.sponsor
            });
        },
        focusCard(ev: MouseEvent | PointerEvent) {
            const el = (this.$refs.descr_text as HTMLElement);
            if(!el) return;

            const evType  = (ev as PointerEvent).pointerType ?? 'mouse';

            // Check if action comes from a cell phone touch event in which case the hover is
            // detected wrongly (thinks its hovered, but it's not on a cellphone)
            const hasHover = (el.parentElement?.querySelector(':hover') === el && evType !== "touch");
            const hasFocus = el.classList.contains('expanded') ;
            if(!(hasFocus || hasHover)) {
                el.classList.add('expanded');
                ev.stopPropagation();
                ev.preventDefault();
                return false;
            }
            else {
                el.classList.remove('expanded');
                this.hrefClick(ev, this.sponsor.websiteUrl)
            }
        }
    }
});
